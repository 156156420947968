/**
 * 系统配置
 */
 import req from './index.js'
 
//设备台账管理
export const equipmentLedgerAlarmTypeSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerAlarmTypeSelect',param) };//查询
export const equipmentLedgerAlarmLevelSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerAlarmLevelSelect',param) };//查询
export const equipmentLedgerAdd = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerAdd',param) };//查询
export const equipmentLedgerOrganizationSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerOrganizationSelect',param) };//查询
export const equipmentLedgerQuery = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerQuery',param) };//查询
export const equipmentLedgerModification = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerModification',param) };//修改
export const equipmentLedgerDelete = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerDelete',param) };//删除
export const equipmentLedgerQueryLimit = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerQueryLimit',param) };//删除
export const equipmentLedgerWorkShopSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerWorkShopSelect',param) };//所属车间选择
export const equipmentLedgerLineSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerLineSelect',param) };//所属产线选择
export const equipmentLedgerManufacturersSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerManufacturersSelect',param) };//设备厂家选择
export const equipmentLedgerMaintenancerSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerMaintenancerSelect',param) };//设备保养人选择
export const equipmentLedgerAdminiSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerAdminiSelect',param) };//设备管理员选择
export const equipmentLedgerCompanySelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerCompanySelect',param) };//所属公司选择
export const equipmentLedgerInspectionSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerInspectionSelect',param) };//设备点检人选择
export const equipmentLedgerDepartment = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerDepartment',param) };//所属部门
export const equipmentIDverificationQuery = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentIDverificationQuery',param) };//新增设备台账时，校验设备ID
//第三方设备台账
export const thirdpartyDeviceStockQueryLimit = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/ThirdpartyDeviceStockQueryLimit',param) }
//所有数据接口
export const thirdpartyDeviceStockQuery = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/ThirdpartyDeviceStockQuery',param) }
//新增接口
export const thirdpartyDeviceStockInsert = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/ThirdpartyDeviceStockInsert',param) }
//删除接口
export const thirdpartyDeviceDelete = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/ThirdpartyDeviceDelete',param) }
//更新接口
export const thirdpartyDeviceStockUpdate = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/ThirdpartyDeviceStockUpdate',param) }

// KPI算法配置
//  export const kpiConfig = param => { return req.post('EMS.Thing.Api.Setting/Services/KpiConfig',param) };//KPI清单
//  export const kpiConfigInfoConfigQuery = param => { return req.post('EMS.Thing.Api.Setting/Services/KpiConfigInfoConfigQuery',param) };//KPI信息配置查询
//  export const kpiConfigInfoConfigModification = param => { return req.post('EMS.Thing.Api.Setting/Services/KpiConfigInfoConfigModification',param) };//KPI信息配置修改
 export const kpiConfigExpressionQuery = param => { return req.post('EMS.Thing.Api.Setting/Services/KpiConfigExpressionQuery',param) };//KPIExpression查询
 export const kpiConfigExpressionModification = param => { return req.post('EMS.Thing.Api.Setting/Services/KpiConfigExpressionModification',param) };//KPIExpression修改


// 设备类型配置
 export const equipmentTypeConfigList = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentTypeConfigList',param) };//查询
 export const equipmentTypeConfigModification = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentTypeConfigModification',param) };//编辑
 export const equipmentTypeConfigAdd = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentTypeConfigAdd',param) };//增加
 export const equipmentTypeConfigDelete = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentTypeConfigDelete',param) };//删除

// 设备等级配置
 export const equipmentLevelConfigList = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLevelConfigList',param) };//查询
//  export const equipmentTypeConfigAdd = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLevelConfigAdd',param) };//增加
//  export const equipmentLevelConfigDelete = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLevelConfigDelete',param) };//删除


// 设备文档 
export const equipmentDocumentQuantityStatistics = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentDocumentQuantityStatistics',param) };//设备文档管理数量统计
export const equipmentDocumentList = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentDocumentList',param) };//设备文档清单
export const equipmentDocumentUpload = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentDocumentUpload',param) };//设备文档上传
export const equipmentDocumentDelete = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentDocumentDelete',param) };//删除设备文档
export const equipmentDocumentModification = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentDocumentModification',param) };//修改设备文档

//报警配置
export const alarmConfigOrganizationSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigOrganizationSelect',param) };//组织选择
export const alarmConfigEquipmentTypeSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigEquipmentTypeSelect',param) };//设备类型选择
export const alarmConfigEquipmentLevelSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigEquipmentLevelSelect',param) };//设备等级选择
export const alarmConfigGetEquipmentListByCategoryAndLevel = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigGetEquipmentListByCategoryAndLevel',param) };//类型等级筛选
export const alarmConfigGetPropertyDefinitionByEuipmentID = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigGetPropertyDefinitionByEuipmentID',param) };//设备ID筛选
export const alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName',param) };//设备ID属性名筛选
export const alarmConfigAddOrUpdateAlerts = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigAddOrUpdateAlerts',param) };//新增
export const alarmConfigDeleteOrDisabledAlert = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigDeleteAlert',param) };//删除
export const alarmConfigEnableOrDisableAlert = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigEnableOrDisableAlert',param) };//激活或去活
export const alarmConfigAlertTypeSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/AlarmConfigAlertTypeSelect',param) };//报警类型查询
export const equipmentAlarmLevelSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentAlarmLevelSelect',param) };//报警等级下拉框
export const equipmentAlarmTypeSelect = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentAlarmTypeSelect',param) };//报警等级下拉框



// 维保配置--评分等级
export const rMIGradeSettingsInsert = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGradeSettingsInsert',param) };//评分等级 新增
export const rMIGradeSettingsDelete = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGradeSettingsDelete',param) };//评分等级 删除
export const rMIGradeSettingsUpdate = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGradeSettingsUpdate',param) };//评分等级 修改
export const rMIGradeSettingsQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGradeSettingsQuery',param) };//评分等级筛选 所有数据
export const rMIGradeSettingsQueryLimit = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGradeSettingsQueryLimit',param) };//评分等级筛选 所有数据
export const rMIGradeCategoryQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGradeCategoryQuery',param) };//评分等级分类 筛选

//  维保配置--班组
export const rMIGroupSettingsInsert = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGroupSettingsInsert',param) };//班组 新增
export const rMIGroupSettingsDelete = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGroupSettingsDelete',param) };//班组 删除
export const rMIGroupSettingsUpdate = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGroupSettingsUpdate',param) };//班组 修改
export const rMIGroupSettingsQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGroupSettingsQuery',param) };//班组筛选 所有数据
export const rMIGroupSettingsQueryLimit = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGroupSettingsQueryLimit',param) };//班组筛选 分页数据
export const rMIGroupCategoryQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGroupCategoryQuery',param) };//班组分类 筛选
export const rMIGroupSettingsExecPersonQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIGroupSettingsExecPersonQuery',param) };//班组 新增 班组人员


// 维保配置--等级
export const rMILevelSettingsInsert = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMILevelSettingsInsert',param) };//等级 新增
export const rMILevelSettingsDelete = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMILevelSettingsDelete',param) };//等级 删除
export const rMILevelSettingsUpdate = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMILevelSettingsUpdate',param) };//等级 修改
export const rMILevelSettingsQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMILevelSettingsQuery',param) };//等级筛选 所有数据
export const rMILevelSettingsQueryLimit = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMILevelSettingsQueryLimit',param) };//等级筛选 分页数据
export const rMILevelCategoryQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMILevelCategoryQuery',param) };//等级分类筛选

//维修配置--类别
export const rMITypeSettingsInsert = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMITypeSettingsInsert', param) };//类别 新增
export const rMITypeSettingsDelete = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMITypeSettingsDelete', param) };//类别 删除
export const rMITypeSettingsUpdate = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMITypeSettingsUpdate', param) };//类别 修改
export const rMITypeSettingsQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMITypeSettingsQuery', param) };//类别筛选 所有数据
export const rMITypeSettingsQueryLimit = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMITypeSettingsQueryLimit', param) };//类别筛选 分页数据
export const rMITypeCategoryQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMITypeCategoryQuery', param) };//类别分类筛选


// 维保配置--备件单位
export const rMISparepartUnitSettingsInsert = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMISparepartUnitSettingsInsert',param) };//备件单位 新增
export const rMISparepartUnitSettingsDelete = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMISparepartUnitSettingsDelete',param) };//备件单位 删除
export const rMISparepartUnitSettingsUpdate = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMISparepartUnitSettingsUpdate',param) };//备件单位 修改
export const rMISparepartUnitSettingsQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMISparepartUnitSettingsQuery',param) };//备件单位 筛选 所有数据
export const rMISparepartUnitSettingsQueryLimit = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMISparepartUnitSettingsQueryLimit',param) };//备件单位 筛选 分页数据 

//维保配置--紧急程度
export const rMIUrgenLevelSettingsInsert = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIUrgenLevelSettingsInsert', param) };//紧急程度 新增
export const rMIUrgenLevelSettingsDelete = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIUrgenLevelSettingsDelete', param) };//紧急程度 删除
export const rMIUrgenLevelSettingsUpdate = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIUrgenLevelSettingsUpdate', param) };//紧急程度 修改
export const rMIUrgenLevelSettingsQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIUrgenLevelSettingsQuery', param) };//紧急程度 筛选 所有数据
export const rMIUrgenLevelSettingsQueryLimit = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIUrgenLevelSettingsQueryLimit', param) };//紧急程度 筛选 分页数据
export const rMIUrgenLevelCategoryQuery = param => { return req.post('EMS.Thing.Api.CommonSettingsManagement/Services/RMIUrgenLevelCategoryQuery', param) };//紧急程度 分类筛选

//维保配置-审批记录
export const authoritymanagementAuthorizerecordQuery = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizerecordQuery', param) };//审批记录筛选 所有数据
export const authoritymanagementAuthorizerecordQueryLimit = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizerecordQueryLimit', param) };//审批记录筛选 分页数据
export const authoritymanagementAuthorizerecordDelete = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizerecordDelete', param) };//审批记录筛选 所有数据

//维保配置-审批顺序
export const authoritymanagementAuthorizesequenceInsert = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizesequenceInsert', param) };//审批顺序配置 新增
export const authoritymanagementAuthorizesequenceDelete = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizesequenceDelete', param) };//审批顺序配置 删除
export const authoritymanagementAuthorizesequenceUpdate = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizesequenceUpdate', param) };//审批顺序配置 修改
export const authoritymanagementAuthorizesequenceQuery = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizesequenceQuery', param) };//审批顺序配置 筛选 所有数据
export const authoritymanagementAuthorizesequenceQueryLimit = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizesequenceQueryLimit', param) };//审批顺序配置 筛选 分页数据
export const authoritymanagementAuthorizesequenceAuthType = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizesequenceAuthType', param) };//审批类型
export const authoritymanagementAuthorizesequenceRoleQuery = param => { return req.post('EMS.Thing.Api.MultiAuthorizeManagement/Services/AuthoritymanagementAuthorizesequenceRoleQuery', param) };//审批顺序配置 新增弹框 角色编号下拉框





//权限管理
export const userConfigQueryRole = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryRole', param) };//查询系统角色
export const userConfigQueryUserCorrelationRole = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryUserCorrelationRole', param) };//查询用户关联的角色
export const userConfigBandingUserAndRole = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigBandingUserAndRole', param) };//绑定用户和角色
export const userConfigUnBandingUserAndRole = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigUnBandingUserAndRole', param) };//解绑用户和角色
export const roleConfigCreateAndUpdateRole = param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigCreateAndUpdateRole', param) }; //添加角色
// export const roleConfigCreateRole = param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigCreateRole', param) }; // 添加角色（新的接口文档将新增和修改接口拆分了）
// export const roleConfigUpdateRole = param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigUpdateRole', param) }; // 修改角色（新的接口文档将新增和修改接口拆分了）
// export const roleConfigDeleteRole = param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigDeleteRole', param) };//删除角色
export const userConfigQueryRoleGroup = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryRoleGroup', param) };//查询系统角色组
export const userConfigQueryRoleCorrelationRoleGroup = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryRoleCorrelationRoleGroup', param) };//查询角色关联的角色组
export const roleConfigBandingRoleGroupAndRole = param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigBandingRoleGroupAndRole', param) };//绑定角色和角色组
export const roleConfigUnBandingRoleGroupAndRole = param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigUnBandingRoleGroupAndRole', param) };//解绑角色和角色组
export const userConfigQueryOrganization = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryOrganization', param) };//查询系统组织
export const userConfigQueryRoleCorrelationOrganization = param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryRoleCorrelationOrganization', param) };//查询角色关联的组织
export const roleConfigBandingOrganizationAndRole= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigBandingOrganizationAndRole', param) };//绑定角色和组织
export const roleConfigUnBandingOrganizationAndRole= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigUnBandingOrganizationAndRole', param) };//解绑角色和组织
export const roleConfigCreateAndUpdateRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigUserList', param) };//添加角色组
export const roleConfigCreateRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigCreateRoleGroup', param) }; // 添加角色组（新的接口文档将新增和修改接口拆分了）
export const roleConfigUpdateRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigUpdateRoleGroup', param) }; // 更新角色组（新的接口文档将新增和修改接口拆分了）
export const roleConfigDeleteRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigDeleteRoleGroup', param) }; // 删除角色组
export const userConfigQueryPermission= param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryPermission', param) }; // 查询系统权限
export const userConfigQueryRoleGroupCorrelationPermission= param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryRoleGroupCorrelationPermission', param) }; // 查询角色组关联的权限
export const roleConfigBandingPermissionAndRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigBandingPermissionAndRoleGroup', param) }; // 绑定角色组和权限
export const roleConfigUnBandingPermissionAndRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigUnBandingPermissionAndRoleGroup', param) }; // 解绑角色组和权限
export const userConfigQueryPage= param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryPage', param) }; // 查询系统页面
export const userConfigQueryRoleGroupCorrelationPage= param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigQueryRoleGroupCorrelationPage', param) }; // 查询角色组关联的页面
export const roleConfigBandingPageAndRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigBandingPageAndRoleGroup', param) }; // 绑定角色组和页面
export const roleConfigUnBandingPageAndRoleGroup= param => { return req.post('EMS.Thing.Api.Setting/Services/RoleConfigUnBandingPageAndRoleGroup', param) }; // 解绑角色组和页面
export const userConfigGenderSelect= param => { return req.post('EMS.Thing.Api.Setting/Services/UserConfigGenderSelect', param) }; // 性别选择
export const userConfigJobSelect= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigJobSelect', param) }; // 职位选择

// linxinsen-职位配置
// 根据职位ID查询系统角色
export const userConfigQueryRoleByUserJobID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryRoleByUserJobID', param) };
// 查询职位绑定的角色
export const userConfigQueryJobCorrelationRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryJobCorrelationRole', param) };
// 绑定职位和角色
export const userConfigBandingJobAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingJobAndRole', param) };
// 解绑职位和角色
export const userConfigUnBandingJobAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingJobAndRole', param) };
// 添加职位
export const roleConfigCreateJob= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigCreateJob', param) };
// 更新职位
export const roleConfigUpdateJob= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigUpdateJob', param) };
// 更新职位
export const roleConfigDeleteJob= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigDeleteJob', param) };



// linxinsen-部门配置
// 部门列表查询
export const userConfigDepartmentSelect= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigDepartmentSelect', param) };
// 添加部门
export const roleConfigCreateDepartment= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigCreateDepartment', param) };
// 更新部门
export const roleConfigUpdateDepartment= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigUpdateDepartment', param) };
// 删除部门
export const roleConfigDeleteDepartment= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigDeleteDepartment', param) };
// 根据部门ID查询系统角色
export const userConfigQueryRoleByUserDepartmentID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryRoleByUserDepartmentID', param) };
// 根据部门ID查询系统角色
export const userConfigQueryDepartmentCorrelationRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryDepartmentCorrelationRole', param) };
// 绑定部门和角色
export const userConfigBandingDepartmentAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingDepartmentAndRole', param) };
// 解绑部门和角色
export const userConfigUnBandingDepartmentAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingDepartmentAndRole', param) };


// linxinsen-start
// 权限管理-角色管理
// 权限管理-角色管理-角色列表查询
export const userConfigRoleSelect= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigRoleSelect', param) };
// 权限管理-角色管理-添加角色
export const roleConfigCreateRole= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigCreateRole', param) };
// 权限管理-角色管理-更新角色
export const roleConfigUpdateRole= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigUpdateRole', param) };
// 权限管理-角色管理-删除角色
export const roleConfigDeleteRole= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigDeleteRole', param) };
// 权限管理-角色管理-根据角色ID查询系统权限
export const userConfigQueryPermissionByUserRoleID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryPermissionByUserRoleID', param) };
// 权限管理-角色管理-根据角色ID查询系统页面
export const userConfigQueryPageByUserRoleID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryPageByUserRoleID', param) };

//权限管理-用户管理
//author：wujinhuan
export const userConfigUserList = param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUserList', param) };//用户信息清单
export const userConfigCreateUser = param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigCreateUser', param) };//添加用户
export const userConfigUpdateUser = param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUpdateUser', param) };//添加用户
export const userConfigDeleteUser = param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigDeleteUser', param) };//添加用户
export const userConfigLockUser = param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigLockUser', param) };//添加用户



//权限管理-用户组
//author：刘勇文
export const userConfigUserGroupSelect= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUserGroupSelect', param) }; // 查询用户组
export const roleConfigCreateUserGroup= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigCreateUserGroup', param) }; // 添加用户组
export const roleConfigUpdateUserGroup= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigUpdateUserGroup', param) }; // 更新用户组
export const roleConfigDeleteUserGroup= param => { return req.post('EMS.Thing.Api.Permission/Services/RoleConfigDeleteUserGroup', param) }; // 删除用户组
export const userConfigQueryUserByUserGroupID = param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryUserByUserGroupID', param) }; // 根据用户组ID查询系统用户
export const userConfigQueryOrganizationByUserGroupID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryOrganizationByUserGroupID', param) }; // 根据用户组ID查询系统组织
export const userConfigQueryRoleByUserGroupID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryRoleByUserGroupID', param) }; // 根据用户组ID查询系统角色
export const userConfigQueryUserGroupCorrelationUser= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryUserGroupCorrelationUser', param) }; // 根据用户组ID查询用户组关联的用户
export const userConfigQueryUserGroupCorrelationRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryUserGroupCorrelationRole', param) }; // 根据用户组ID查询用户组关联的角色
export const userConfigQueryUserGroupCorrelationOrganization= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryUserGroupCorrelationOrganization', param) }; // 根据用户组ID查询系统组织

export const userConfigBandingUserGroupAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingUserGroupAndRole', param) }; // 绑定用户组和角色
export const userConfigUnBandingUserGroupAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingUserGroupAndRole', param) }; // 解绑用户组和角色

export const userConfigBandingUserGroupAndUser= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingUserGroupAndUser', param) }; // 绑定用户组和用户
export const userConfigUnBandingUserGroupAndUser= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingUserGroupAndUser', param) }; // 解绑用户组和用户

export const userConfigBandingUserGroupAndOrganization= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingUserGroupAndOrganization', param) }; // 绑定用户组和组织
export const userConfigUnBandingUserGroupAndOrganization= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingUserGroupAndOrganization', param) }; // 解绑用户组和组织

//权限管理-组织
//author：刘勇文
export const userConfigOrganizationSelect= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigOrganizationSelect', param) }; // 查询组织
export const userConfigQueryRoleByUserOrganizationID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryRoleByUserOrganizationID', param) }; // 根据组织ID查询当前系统的角色
export const userConfigQueryOrganizationCorrelationRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryOrganizationCorrelationRole', param) }; // 根据组织ID查询当前组织关联的角色
export const userConfigBandingOrganizationAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingOrganizationAndRole', param) }; // 绑定组织和角色
export const userConfigUnBandingOrganizationAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingOrganizationAndRole', param) }; // 解绑组织和角色

// 权限管理-角色
// author:刘晓玉  
export const userConfigBandingPermissionAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingPermissionAndRole', param) }; // 绑定权限和角色
export const userConfigBandingPageAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingPageAndRole', param) }; // 绑定页面和角色
export const userConfigUnBandingPermissionAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingPermissionAndRole', param) }; // 解绑权限和角色
export const userConfigUnBandingPageAndRole= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingPageAndRole', param) }; // 解绑页面和角色



//权限管理-部门职位
//author：刘勇文
//export const userConfigDepartmentSelect= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigDepartmentSelect', param) }; // 部门选择框
export const userConfigDepartmentJobSelect= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigDepartmentJobSelect', param) }; // 部门职位选择框
export const userConfigQueryOrganizationByUserDepartmentJobID= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryOrganizationByUserDepartmentJobID', param) }; // 根据部门职位ID查询系统组织
export const userConfigQueryDepartmentJobCorrelationOrganization= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigQueryDepartmentJobCorrelationOrganization', param) }; // 查询部门职位关联的组织
export const userConfigBandingDepartmentJobAndOrganization= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigBandingDepartmentJobAndOrganization', param) }; // 解绑部门职位和组织
export const userConfigUnBandingDepartmentJobAndOrganization= param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigUnBandingDepartmentJobAndOrganization', param) }; // 解绑部门职位和组织